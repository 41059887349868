import { ButtonBase } from "components/rhombus-UI/theme/buttons";
import {
  FlexEndColumn,
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyPageTitle,
  MainParagraph,
} from "components/rhombus-UI/theme/typography";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

const DownloadHeader = ({
  title,
  blurb,
  downloadLink,
  buttonText,
  image,
  imageAlt,
  onClickFunction = null,
}) => {
  return (
    <SectionContainer>
      <SectionInner>
        <SectionCol style={{ alignItems: "center" }}>
          <TextContainer style={{ gap: "2.5rem" }}>
            <FancyPageTitle>{title}</FancyPageTitle>
            <MainParagraph>{blurb}</MainParagraph>
            <ButtonBase
              download
              href={downloadLink}
              target="_blank"
              onClick={onClickFunction && onClickFunction}
            >
              {buttonText}
            </ButtonBase>
          </TextContainer>
        </SectionCol>
        <FlexEndColumn>
          <GatsbyImage
            image={getImage(image)}
            alt={imageAlt}
            style={{
              width: "535px",
              height: "437px",
              borderRadius: "1rem",
              overflow: "hidden",
            }}
          />
        </FlexEndColumn>
      </SectionInner>
    </SectionContainer>
  );
};

export default DownloadHeader;
