import TrialBanner from "components/common/footer-banners/TrialBanner";
import GlobalLayout from "components/page/GlobalLayout";
import { popupCookieExistsAtom } from "components/page/atoms/globalAtoms";
import DownloadHeader from "components/thank-you/DownloadHeader";
import ExperienceCards from "components/thank-you/ExperienceCards";
import { StaticQuery, graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import { useSetAtom } from "jotai";
import Cookies from "js-cookie";

export default function physicalSecurityChecklistThankYouPage() {
  const setPopupCookie = useSetAtom(popupCookieExistsAtom);

  const renderContent = data => {
    return (
      <GlobalLayout>
        <Helmet>
          <title>Your Checklist Is Ready!</title>
          <meta
            name="description"
            content="Smarter, safer spaces are just a a click away. Download the Physical Security Checklist below and start assessing your security operations."
          />
        </Helmet>
        <DownloadHeader
          title="Your Checklist Is Ready!"
          blurb="Smarter, safer spaces are just a a click away. Download the Physical Security Checklist below and start assessing your security operations."
          downloadLink="/files/rhombus-physical-security-checklist.pdf"
          buttonText="Download Checklist"
          image={data.checklist}
          imageAlt="Physical Security Checklist"
          onClickFunction={() => {
            Cookies.set(
              "physical-security-checklist-popup-never",
              "user-downloaded-checklist",
              {
                expires: 2147483647,
              }
            );
            setPopupCookie(true);
          }}
        />
        <ExperienceCards />
        <TrialBanner />
      </GlobalLayout>
    );
  };
  const GET_IMAGES = graphql`
    query {
      checklist: file(
        relativePath: {
          eq: "components/thank-you/img/physical-security-checklist-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `;

  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
